import React from "react"
import Layout from "../layout/layout"
import Wrapper from "../components/wrapper"

const AboutPage = () => (
  <Layout title="About" description="Contact information">
    <section id="contact-short">
      <Wrapper>
        <h1>Contact information</h1>
        <p>
          Email:{" "}
          <a href="mailto:krystian.fluder@outlook.com">
            krystian.fluder@outlook.com
          </a>
        </p>
        <p>
          Github:{" "}
          <a
            href="https://github.com/krystianfluder"
            target="_blank"
            rel="noreferrer"
          >
            https://github.com/krystianfluder
          </a>
        </p>
      </Wrapper>
    </section>

    <section id="contact-map">
      <iframe
        style={{
          filter: "grayscale(90%)",
        }}
        title="cracow"
        width="100%"
        height="300"
        frameBorder="0"
        scrolling="no"
        marginHeight="0"
        marginWidth="0"
        src="https://www.openstreetmap.org/export/embed.html?bbox=19.746894836425785%2C49.944813166695106%2C20.177078247070316%2C50.14896640024682&amp;layer=mapnik"
      ></iframe>
    </section>

    {/* <section id="contact-form">
      <h1>Contact form</h1>
      <Formik
        initialValues={{ email: "", message: "" }}
        validationSchema={Yup.object().shape({
          email: Yup.string().email("Invalid email").required("Required"),
          message: Yup.string()
            .min(5, "Too Short!")
            .max(500, "Too Long!")
            .required("Required"),
        })}
        onSubmit={(values, { setSubmitting }) => {
          setTimeout(() => {
            alert(JSON.stringify(values, null, 2))
            setSubmitting(false)
          }, 400)
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
          <form onSubmit={handleSubmit} noValidate>
            <div className="form-group">
              <label htmlFor="contact-email">Email address</label>
              <input
                id="contact-email"
                type="email"
                name="email"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.email}
              />
              <p className="error">
                {errors.email && touched.email && errors.email}
              </p>
            </div>
            <div className="form-group">
              <label htmlFor="contact-message">Message</label>
              <textarea
                id="contact-message"
                type="message"
                name="message"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.message}
              />
              <p className="error">
                {errors.message && touched.message && errors.message}
              </p>
            </div>
            <button type="submit" disabled={isSubmitting}>
              Submit
            </button>
          </form>
        )}
      </Formik>
    </section> */}
  </Layout>
)

export default AboutPage
